<template>
    <div>
        <v-img v-for="(imageUrl, index) in imagesUrl"
               :img-url="imageUrl"
               v-bind:key="index"
               :height="size.height"
               :width="size.width"
               :src="imageUrl">
        </v-img>
    </div>
</template>

<script>
import axios from "axios";
const URL = "https://led.pucher.tv";
import { orderBy } from "lodash";

export default {
  name: 'Home',
    data() {
        return {
            imageReload: 0,
            availablePositions: []
        }
    },
    computed: {
      size() {
          if (this.$route.query.size) {
              return { width: this.$route.query.size.split(",")[0], height: this.$route.query.size.split(",")[1] };
          }
          return { width: 1920, height: 500 };
      },
      imagesUrl() {
          return this.availablePositions.map(position => {
              return URL + "/server/image.php?area=" + this.$route.query.area + "&position=" + position.id + "&reload=" + this.imageReload
          });
      }
    },
    async mounted() {
      const system = this.$route.query.system;
      const d = new Date();
      this.imageReload=  d.getHours();
      axios.get(URL + "/server/ping.php?initial=true&system=" + system + "&password=" + this.$route.query.password);
      const availablePositions = await axios.get(URL + "/server/positions.php?area=" + this.$route.query.area + "&password=" + this.$route.query.password)
      this.availablePositions = orderBy(availablePositions.data, ['id'], ['asc']);
      this.reload();
    },
    methods: {
      reload() {
          const d = new Date();
          if (d.getMinutes() % 10 === 0) {
              const system = this.$route.query.system;
              axios.get(URL + "/server/ping.php?initial=false&system=" + system + "&password=" + this.$route.query.password);
          }
          if (d.getMinutes() === 0){
              this.imageReload = d.getHours();
          }
          setTimeout(() => {
              this.reload();
          }, 60000)
      }
    }
}
</script>
