<template>
    <v-container v-if="login">
        <v-card>
            <v-card-title><span>Neue Fotos hochladen</span></v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-flex v-for="(upload, index) in uploads" :key="index" v-cloak @drop.prevent="addDropFile($event, upload)" @dragover.prevent>
                    <v-row>
                        <v-col>
                            <v-file-input v-model="upload.image" label="Foto auswählen"></v-file-input>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="upload.area"
                                :items="availableAreas"
                                item-value="aid"
                                item-text="area"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="upload.position"
                                :items="getAvailablePositions(upload.area)"
                                item-value="pid"
                                item-text="position"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-menu
                                    ref="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="upload.date"
                                            label="Datum"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        locale="de-at"
                                        range
                                        v-model="upload.date"
                                        :min="new Date().toISOString().substr(0, 10)"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-range-slider ticks="always" min="0" height="5px" max="23" step="1" hint="Stunden wählen" v-model="upload.hours">
                                <template v-slot:prepend>
                                    <v-text-field
                                            :value="upload.hours[0]"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            style="width: 60px"
                                            @change="$set(upload.hours, 0, $event)"
                                    ></v-text-field>
                                </template>
                                <template v-slot:append>
                                    <v-text-field
                                            :value="upload.hours[1]"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            style="width: 60px"
                                            @change="$set(upload.hours, 1, $event)"
                                    ></v-text-field>
                                </template>
                            </v-range-slider>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex>
                    <v-btn block @click="addImage">(+) Zusätzliches Foto</v-btn>
                </v-flex>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="saveImages" color="green">Fotos speichern</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar
                v-model="message.show"
                :color="message.color"
                :timeout="4000"
        >
            {{ message.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        icon
                        v-bind="attrs"
                        @click="message.show = false"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from "axios";
    import { uniqBy } from "lodash";
    //const URL = "http://proxy.florian-prey.at/proxy.php?url=http://localhost/ledServer/";
    const URL = "https://led.pucher.tv/server/";

    export default {
        name: "Upload",
        data() {
          return {
              uploads: [{
                  image: undefined,
                  area: "",
                  position: "",
                  date: null,
                  hours: [0,24],
              }],
              availableAreas: [],
              allAvailablePositions: [],
              message: {
                  show: false,
                  color: "error",
                  message: ""
              },

          }
        },
        async mounted() {
            const systems = await axios.get(URL + "systems.php?password=" + this.$route.query.password);
            this.allAvailablePositions = systems.data;
            this.availableAreas = uniqBy(systems.data, 'aid');
        },
        computed: {
            login() {
                return this.$route.query.password === "IN4782fo?";
            }
        },
        methods: {
            getAvailablePositions(area) {
                return this.allAvailablePositions.filter(position => {
                    return position.aid === area
                });
            },
            addDropFile(e, upload) {
                upload.image = e.dataTransfer.files[0];
            },
            addImage() {
                this.uploads.push({
                    image: undefined,
                    area: "",
                    position: "",
                    date: null,
                    hours: [0,24]
                })
            },
            async saveImages() {
                const formData = new FormData();
                this.uploads.map((upload, index) => {
                    formData.append("files" + index, upload.image, upload.image.name);
                })
                formData.append("images", JSON.stringify(this.uploads));
                const response = await axios.post(URL + "upload.php?creator=admin&password=" + this.$route.query.password, formData);
                this.message.show = true;
                const data = response.data;
                if (data.status) {
                    this.message.color = "success";
                    this.message.message = "Speichern erfolgreich";
                } else {
                    this.message.color = "error";
                    this.message.message = "Speichern fehlgeschlagen";
                }
                if (data.message !== undefined) {
                    this.message.message = data.message
                }
            }
        }
    }
</script>

<style scoped>

</style>
